<template>
    <v-row>
        <v-col cols="12">
            <base-card>
                <v-card-title>
                    {{ $t('admin.configs.title') }}
                    <!--                    <v-spacer />
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />-->
                </v-card-title>

                <v-row class="mx-8">
                    <v-col
                        class="my-0 py-0"
                        cols="12"
                        md="6"
                        lg="3"
                        sm="6"
                    >
                        <v-switch
                            v-model="config.users.canRegister"
                            :label="$t('admin.configs.canRegister')"
                            @change="changeStateCanRegister($event)"
                        />
                    </v-col>

                    <v-col
                        cols="12"
                        sm="4"
                        md="3"
                        lg="2"
                    >
                        <v-datetime-picker label="Fecha fin de Reconocimientos" v-model="config.recognition.end">
                            <template v-slot:dateIcon>
                                <v-icon v-text="">mdi-calendar</v-icon>
                            </template>

                            <template v-slot:timeIcon>
                                <v-icon>mdi-clock-time-four-outline</v-icon>
                            </template>
                        </v-datetime-picker>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="4"
                        md="3"
                        lg="2"

                    >
                        <v-datetime-picker label="Fecha inicio de Votación" v-model="config.votation.start">
                            <template v-slot:dateIcon>
                                <v-icon v-text="">mdi-calendar</v-icon>
                            </template>

                            <template v-slot:timeIcon>
                                <v-icon>mdi-clock-time-four-outline</v-icon>
                            </template>
                        </v-datetime-picker>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="4"
                        md="3"
                        lg="2"

                    >
                        <v-datetime-picker label="Fecha fin de Votación" v-model="config.votation.end">
                            <template v-slot:dateIcon>
                                <v-icon v-text="">mdi-calendar</v-icon>
                            </template>

                            <template v-slot:timeIcon>
                                <v-icon>mdi-clock-time-four-outline</v-icon>
                            </template>
                        </v-datetime-picker>
                    </v-col>

                    <v-col
                        cols="12"
                        sm="4"
                        md="3"
                        lg="2"
                    >
                        <v-datetime-picker label="Fecha fin de Competición" v-model="config.competition.end">
                            <template v-slot:dateIcon>
                                <v-icon v-text="">mdi-calendar</v-icon>
                            </template>

                            <template v-slot:timeIcon>
                                <v-icon>mdi-clock-time-four-outline</v-icon>
                            </template>
                        </v-datetime-picker>
                    </v-col>

                </v-row>


            </base-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from '@/plugins/axios'


// import { mapGetters } from 'vuex'

export default {
    name: "Configs",
    data: function () {
        return {
            config: this.getInitialConfig()
        }
    },
    async created() {
        await this.loadConfig();

        this.$watch('config.competition.end', () => {
            this.changeCompetition();
        });

        this.$watch('config.recognition.end', () => {
            // console.log('config.recognition', this.config.recognition.end)
            this.changeRecognition();
        });

        this.$watch('config.votation', () => {
            // console.log('config.recognition', this.config.recognition.end)
            this.changeVotation();
        }, {deep: true});
    },
    watch: {},
    methods: {
        getInitialConfig() {
            const config = {
                users: {canRegister: false},
                competition: {end: ""},
                recognition: {end: ""},
                votation: {start: "", end: ""}
            }
            return config;
        },
        parseConfig(id, configs) {
            const found = configs.find(current => current.id == id);

            if (found) {
                const options = found?.attributes?.options;
                try {
                    if (options.start) options.start = new Date(options.start);
                    if (options.end) options.end = new Date(options.end);
                } catch (e) {
                }

                return options;
            }
            return {};
        },
        async loadConfig() {

            try {

                const configs = await axios
                    .get('configs');

                const dataConfigs = configs?.data?.data;

                if (dataConfigs) {
                    const configs = this.getInitialConfig();

                    this.config.users = Object.assign(configs.users, this.parseConfig("1", dataConfigs));
                    this.config.competition = Object.assign(configs.competition, this.parseConfig("2", dataConfigs));
                    this.config.recognition = Object.assign(configs.recognition, this.parseConfig("3", dataConfigs));
                    this.config.votation = Object.assign(configs.votation, this.parseConfig("4", dataConfigs));

                }

            } catch (e) {
                console.log('error', e)
            }
        },

        async updateConfig(id, attributes) {

            var dataSubmit = {
                data: {
                    type: 'configs',
                    id,
                    attributes
                }
            }

            try {
                /* const result = */
                await axios({
                    method: 'patch',
                    url: `configs/${id}`,
                    data: dataSubmit
                })

                /* if (result.status === 200 || result.status === 201 || result.status === 204) {

                    const actionMSG = this.editedItem.id ? 'updatedOK' : 'createdOK'
                    this.$store.dispatch('snackbar/success', this.$t('common.' + actionMSG))
                } */
            } catch (error) {
                /* if (error.response.status === 422) {
              console.log('********** errors ********')
              console.log(error.response.data.errors)

                if (error.response?.data?.errors?.length) {

                }
            } */

                this.$store.dispatch('snackbar/error', this.$t('common.accionError'))
            }
        },
        async changeStateCanRegister() {
            const attributes = {
                name: 'users',
                options: JSON.stringify(this.config.users)
            };

            this.updateConfig('1', attributes);
        },
        async changeCompetition() {
            const value = this.config.competition.end
            const end = value instanceof Date ?
                this.dateHelper.toStringMadridTimeZone(value) : '';

            const attributes = {
                options: JSON.stringify({
                    end
                })
            };

            this.updateConfig('2', attributes);
        },
        async changeRecognition() {
            const value = this.config.recognition.end
            const end = value instanceof Date ?
                this.dateHelper.toStringMadridTimeZone(value) : '';

            const attributes = {
                options: JSON.stringify({
                    end
                })
            };

            this.updateConfig('3', attributes);
        },
        async changeVotation() {
            const valueEnd = this.config.votation.end
            const end = valueEnd instanceof Date ?
                this.dateHelper.toStringMadridTimeZone(valueEnd) : '';

            const valueStart = this.config.votation.start
            const start = valueStart instanceof Date ?
                this.dateHelper.toStringMadridTimeZone(valueStart) : '';

            const attributes = {
                options: JSON.stringify({
                    start,
                    end
                })
            };

            this.updateConfig('4', attributes);
        }
    }
};
</script>
